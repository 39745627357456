@let blok = block();

<div class="w-full px-5 py-12"
     id="footer">
    <div class="max-w-content-width mx-auto">
        @if (blok.logo) {
            <s1-image [block]="{asset:blok.logo}" id="footer-logo"  class="block mx-auto max-w-64 my-4" />
        }
        <div class="grid gap-5 grid-cols-1 md:grid-cols-3">
            <div class="mb-6">
                @for (child of blok.footer_children1; track child._uid) {
                    <s1-cms-component [blok]="child"/>
                }
            </div>
            <div class="mb-6">
                @for (child of blok.footer_children2; track child._uid) {
                    <s1-cms-component [blok]="child"/>
                }
            </div>
            <div class="mb-6">
                @for (child of blok.footer_children3; track child._uid) {
                    <s1-cms-component [blok]="child"/>
                }
            </div>
            <div class="md:row-start-2 md:col-start-3 mb-6 flex gap-6">
                @for (child of blok.footer_children6; track child._uid) {
                    <s1-cms-component [blok]="child"/>
                }
            </div>
            <div class="md:row-start-2 md:col-start-1 mb-6">
                @for (child of blok.footer_children4; track child._uid) {
                    <s1-cms-component [blok]="child"/>
                }
            </div>
            <div class="mb-6">
                @for (child of blok.footer_children5; track child._uid) {
                    <s1-cms-component [blok]="child"/>
                }
            </div>
        </div>
    </div>
</div>
@for (child of blok.footer_recruit; track child._uid) {
    <s1-cms-component [blok]="child"/>
}
