import { ChangeDetectionStrategy, Component, effect, inject, input, signal, ViewEncapsulation } from '@angular/core';
import { IBlogBlok, S1Layout } from '@seven1/model';
import { SeoService } from '@seven1/angular/seo';
import { CmsComponent } from '@seven1/angular-storyblok-cms';
import { LayoutWrapper } from '@seven1/angular-storyblok-layout';

@Component({
    selector: 'app-blog-wrapper',
    imports: [CmsComponent, LayoutWrapper],
    templateUrl: './blog.wrapper.html',
    styleUrl: './blog.wrapper.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-blog',
    }
})
export class BlogWrapper {
    private _seo = inject(SeoService);
    block = input.required<IBlogBlok>();
    layout = input.required<S1Layout>();
    page = signal(1);

    constructor() {
        effect(() => {
            const blok = this.block();
            this._seo.addPageMeta(blok);
        });
    }
}
