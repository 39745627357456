@let blok = block();
<s1-layout [block]="layout()">
    @for (child of blok.body; track child['_uid']) {
        <s1-cms-component [blok]="child"/>
    }
    <section class="app-blog--wrapper">
        <div class="app-blog--main">
            @for (child of blok.blogBody; track child['_uid']) {
                <s1-cms-component [blok]="child"/>
            }
        </div>
        @if (blok.sidebar?.length) {
            <aside class="app-blog--aside">
                @for (child of blok.sidebar; track child['_uid']) {
                    <s1-cms-component [blok]="child"/>
                }
            </aside>
        }
    </section>
</s1-layout>
