.app-blog-article {
    &--wrapper {
        @apply flex flex-col md:flex-row items-start justify-center max-w-content-width mx-auto;
    }
    &--main {
        @apply flex-grow;
    }
    &--aside {
        @apply w-full md:max-w-80 px-6 md:pl-0 min-[1500px]:pr-0;
    }
}
