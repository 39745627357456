import { ChangeDetectionStrategy, Component, inject, input, ViewEncapsulation } from '@angular/core';
import { animate, sequence, state, style, transition, trigger } from '@angular/animations';
import { NgOptimizedImage,  } from '@angular/common';
import { NavigationService } from '@seven1/angular-storyblok/layout';
import { CmsComponent } from '@seven1/angular-storyblok-cms';
import { ISidebarBlock } from './sidebar.model';

@Component({
    selector: 's1-sidebar',
    imports: [NgOptimizedImage, CmsComponent],
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        role: 'navigation',
    },
    animations: [
        trigger('sidebarAnimation', [
            state('open', style({
                transform: 'translateX(0)',
                display: 'block',
            })),
            state('closed', style({
                display: 'none',
                transform: 'translateX(100%)',
            })),
            transition('open => closed', [
                sequence([
                    animate('300ms ease-in', style({
                        transform: 'translateX(100%)',
                    })),
                    animate('1ms', style({
                        display: 'none',
                    })),
                ]),
            ]),
            transition('closed => open', [
                style({
                    display: 'block',
                    transform: 'translateX(100%)',
                }),
                animate('300ms ease-out', style({
                    transform: 'translateX(0)',
                })),
            ]),
        ]),
    ]
})
export class SidebarComponent {
    block = input.required<ISidebarBlock>();
    navigationService = inject(NavigationService);
}
