import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ISbImageLoadParams } from '@seven1/model';
import {DesanitizeSlashPipe} from "@seven1/angular/utils";
import { ImageService } from '@seven1/angular-storyblok/image';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CachedUrlResolverPipe, SbLinkHelper } from '@seven1/angular-storyblok/link';
import { IImageBlock } from './image.model';
import { RouterLinkPipe } from '@seven1/angular-storyblok-components/cta';

@Component({
    selector: 's1-image',
    imports: [
        NgOptimizedImage,
        CachedUrlResolverPipe,
        DesanitizeSlashPipe,
        RouterLink,
        NgTemplateOutlet,
        NgClass,
        RouterLinkPipe,
    ],
    templateUrl: './image.component.html',
    styleUrl: './image.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-image',
        '[class]': 'displayClasses()',
    },
})
export class ImageComponent implements OnInit {
    private _images = inject(ImageService);
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);
    private _route = inject(ActivatedRoute);
    private _document = inject(DOCUMENT);

    block = input.required<IImageBlock>();
    sizes = input<string>('100vw');
    loadParams = computed<ISbImageLoadParams>(() => {
        const block = this.block();
        const params = (this._images.extractUrlInfo(block.asset.filename) || {}) as ISbImageLoadParams;
        params.quality = block.quality;
        return params;
    });
    classes = computed(() => {
        const block = this.block();
        return [
            'w-full',
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getBorderRadiusClasses(block),
            ...this._tailwind.getSizeClasses(block),
        ];
    });

    displayClasses = computed(() => {
        return this._tailwind.getDisplayClasses(this.block());
    });

    sanitizedLink = computed(() => {
        const link = this.block().link;
        if (link !== undefined) {
            return SbLinkHelper.sanitizeLink(link, this._sanitizer);
        }
        return '';
    });

    ngOnInit() {
        this._route.fragment.subscribe((fragment) => {
            if (fragment) {
                this.scrollTo(fragment);
            }
        });
    }

    scrollTo(fragment?: string): void {
        if (fragment) {
            const element = this._document.getElementById(fragment);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
