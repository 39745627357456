s1-sidebar {
    a {
        @apply inline-block transition-transform duration-700 text-md md:text-xl font-normal origin-left text-primary my-5 max-w-full;

        &.s1-link:hover {
            @apply transition-all duration-300 text-primary text-md md:text-2xl;
        }
    }
    s1-button a {
        @apply my-0;
    }

    s1-phone-number {
        a {
            @apply font-bold text-lg;
        }
    }

    a.active-link{
        @apply font-extrabold;
    }
}
