@let blok = block();

<header s1-header
        [block]="{
        headerPhoneNumber:blok.headerPhoneNumber,
        headerImage:blok.headerImage,
        headerImageLink:blok.headerImageLink, variant:blok.phoneVariant
     }">
</header>

<s1-sidebar [block]="{nav_children:blok.nav_children}" />

<main class="s1-main bg-transparent" #main [ngStyle]="{
  '--height-header': headerHeight(),
  '--height-footer': footerHeight(),
}">
    <ng-content />
</main>

<s1-footer [block]="{
    logo:blok.footerLogo,
    footerBackgroundColor:blok.footerBackgroundColor,
    footer_children1:blok.footer_children1,
    footer_children2: blok.footer_children2,
    footer_children3: blok.footer_children3,
    footer_children4: blok.footer_children4,
    footer_children5: blok.footer_children5,
    footer_children6: blok.footer_children6,
    footer_recruit: blok.footer_recruit,
}" />
