import { ChangeDetectionStrategy, Component, effect, inject, input, ViewEncapsulation } from '@angular/core';
import { IBlogCategoryBlok, S1Layout } from '@seven1/model';
import { SeoService } from '@seven1/angular/seo';
import { CmsComponent } from '@seven1/angular-storyblok-cms';
import { LayoutWrapper } from '@seven1/angular-storyblok-layout';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-blog-category-wrapper',
    imports: [LayoutWrapper, CmsComponent, NgClass],
    templateUrl: './category.wrapper.html',
    styleUrl: './category.wrapper.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-blog-category-wrapper',
    }
})
export class BlogCategoryWrapper {
    private _seo = inject(SeoService);
    block = input.required<IBlogCategoryBlok>();
    layout = input.required<S1Layout>();

    constructor() {
        effect(() => {
            const blok = this.block();
            this._seo.addPageMeta(blok);
        });
    }
}
