import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, ViewEncapsulation } from '@angular/core';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { IFooterBlock } from './footer.model';
import { CmsComponent } from '@seven1/angular-storyblok-cms';
import { ImageComponent } from '@seven1/angular-storyblok-components/image';

@Component({
    selector: 's1-footer',
    imports: [CmsComponent, ImageComponent], // ImageComponent
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
    },
})
export class FooterComponent {
    elRef = inject(ElementRef<HTMLElement>);
    block = input.required<IFooterBlock>();
    private _tailwind = inject(TailwindService);

    classes = computed(() => {
        const block = this.block();
        return ['block', ...this._tailwind.getBackgroundColorClasses({ backgroundColor: block.footerBackgroundColor })];
    });
}
