@let blok = block();

<div class="mx-auto max-w-content-width flex justify-between items-center flex-1 gap-5 my-7 h-12">
    <ng-container [ngTemplateOutlet]="logo"/>
    <div class="flex gap-5">
        <s1-phone-number [block]="{phoneNumber:blok.headerPhoneNumber, variant:blok.variant}" class="hidden sm:flex"/>
        <button
            (click)="navigationService.toggleNav()"
            [attr.aria-label]="navigationService.navOpen() ? 'Close menu' : 'Open menu'">
            <!-- todo: replace src -->
            <img
                ngSrc="/icons/hamburger-menu.svg"
                width="64"
                height="64"
                [alt]="navigationService.navOpen() ? 'Close menu' : 'Open menu'"
                priority="true"
            />
        </button>
    </div>
</div>


<ng-template #logo>
    @if (blok.headerImageLink.cached_url === '') {
        <s1-image [block]="{asset:blok.headerImage,priority: true}" class="min-w-24"/>
    } @else {
        @switch (blok.headerImageLink.linktype) {
            @case ('url') {
                <a [href]="sanitizedLink() | desanitizeSlash"
                   [target]="blok.headerImageLink.target"
                   [rel]="blok.headerImageLink.target === '_blank' ? 'noopener noreferrer' : undefined"
                class="min-w-40">
                    <s1-image [block]="{asset:blok.headerImage,priority: true}"/>
                </a>
            }
            @case ('story') {
                <a [routerLink]="blok.headerImageLink.cached_url | sbRouterLink | cachedUrlResolver"
                   [fragment]="blok.headerImageLink.anchor"
                   [target]="blok.headerImageLink.target"
                class="min-w-40">
                    <s1-image [block]="{asset:blok.headerImage,priority: true}"/>
                </a>
            }

        }
    }
</ng-template>
