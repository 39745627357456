import {
    ChangeDetectionStrategy,
    Component,
    computed, ElementRef,
    HostListener,
    inject,
    input,
    signal,
    ViewEncapsulation
} from '@angular/core';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { DesanitizeSlashPipe } from '@seven1/angular/utils';
import { RouterLink } from '@angular/router';
import { PlatformService } from '@seven1/angular/ssr';
import { CachedUrlResolverPipe, SbLinkHelper } from '@seven1/angular-storyblok/link';
import { NavigationService } from '@seven1/angular-storyblok/layout';
import { IHeaderBlock } from './header.model';
import { PhoneNumberComponent, RouterLinkPipe } from '@seven1/angular-storyblok-components/cta';
import { ImageComponent } from '@seven1/angular-storyblok-components/image';

@Component({
    selector: 'header[s1-header]',
    imports: [
        NgOptimizedImage,
        NgTemplateOutlet,
        CachedUrlResolverPipe,
        DesanitizeSlashPipe,
        RouterLink,
        RouterLinkPipe,
        PhoneNumberComponent,
        ImageComponent,
        // PhoneNumberComponent,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 's1-header',
        '[class.bg-white]': 'isScrolled()',
    },
})
export class HeaderComponent {
    block = input.required<IHeaderBlock>();
    navigationService = inject(NavigationService);
    elRef = inject(ElementRef<HTMLDivElement>);
    _sanitizer = inject(DomSanitizer);
    platformService = inject(PlatformService);

    sanitizedLink = computed(() => {
        return SbLinkHelper.sanitizeLink(this.block().headerImageLink, this._sanitizer);
    });

    isScrolled = signal(false);

    @HostListener('window:scroll', [])
    onScroll(): void {
        if (this.platformService.isBrowser) {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            this.isScrolled.set(scrollPosition > 100);
        }
    }
}
