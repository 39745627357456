@let blok = block();
<s1-layout [block]="layout()" [ngClass]="!blok.body?.length ? 'pt-28 lg:pt-32' : ''">
        @for (child of blok.body; track child['_uid']) {
            <s1-cms-component [blok]="child"/>
        }
    <section class="app-blog-category-wrapper--wrapper">
        <div class="app-blog-category-wrapper--main">
            @for (child of blok.blogBody; track child['_uid']) {
                <s1-cms-component [blok]="child"/>
            }
        </div>
        @if (blok.sidebar?.length) {
            <aside class="app-blog-category-wrapper--aside">
                @for (child of blok.sidebar; track child['_uid']) {
                    <s1-cms-component [blok]="child"/>
                }
            </aside>
        }
    </section>
</s1-layout>
