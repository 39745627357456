import { inject, Pipe, PipeTransform } from '@angular/core';
import { STORYBLOK_CONFIG, unprefixSlug } from '@seven1/angular-storyblok/api';

@Pipe({
    name: 'sbRouterLink',
})
export class RouterLinkPipe implements PipeTransform {
    private _config = inject(STORYBLOK_CONFIG);

    transform(routerLink: string | undefined | null): string {
        const res = unprefixSlug(routerLink || '', this._config.storyBasePath);
        if (res.startsWith('/')) {
            return res.substring(1, res.length);
        }
        return res;
    }
}
