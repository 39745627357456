import { Injectable } from '@angular/core';
import {
    BorderRadiusOption,
    DisplayOption,
    DisplayPropertyType,
    FlexAlignOption,
    FlexDirectionOption,
    FlexJustifyOption,
    FlexWrapOption,
    FontSizeOption,
    FontWeightOption,
    GapSizeOption,
    IBorderRadiusStyle,
    IDisplayStyle,
    IGapStyle,
    ISizeStyle,
    ISpacingStyle,
    ITypographyStyle,
    MaxWidthOption,
    ScreenSize,
    SpacingSizeOption,
    TailwindResponsivePrefixes,
    TextAlignOption,
    TextColorOption,
    IFlexStyle,
    BackgroundColorOption,
    IBackgroundColorStyle,
    IBackgroundOpacityStyle,
    BackgroundOpacityOption,
    OpacityOption,
    IOpacityStyle,
    TextTransformOption,
    LetterSpacingOption,
    ColSpanOption,
    IColSpanSingleStyle,
    SizeType,
    IBoxShadowStyles,
    BoxShadowOption,
    IBackgroundShadeStyle,
    BackgroundShadesOption,
    AssetFixWidthOption,
    BackgroundSizeOption,
    IBackgroundSizeStyle,
    BackgroundPositionOption,
    IBackgroundPositionStyle, IGradientDirection, GradientDirectionOption
} from '@seven1/model';

export type SpacingType = 'm' | 'p';
export type SpacingPlace = 'x' | 'y' | 't' | 'b' | 'l' | 'r' | '';

export type TypographyProperty = 'color' | 'fontSize' | 'fontWeight' | 'textAlign' | 'italic' | 'textTransform' | 'letterSpacing';
export type TypographyPropertyValue<T extends TypographyProperty> = ITypographyStyle[T];

@Injectable({
    providedIn: 'root',
})
export class TailwindService {
    private _getResponsivePrefix(screenSize: ScreenSize = ''): TailwindResponsivePrefixes {
        switch (screenSize) {
            case 'tablet':
                return 'md:';
            case 'desktop':
                return 'lg:';
            default:
                return '';
        }
    }

    /* Display -------------------------------------------------------------- */
    private _getDisplayStyle(display?: DisplayOption, screenSize: ScreenSize = '', displayType: DisplayPropertyType = 'block'): string {
        const prefix: TailwindResponsivePrefixes = this._getResponsivePrefix(screenSize);
        switch (display) {
            /**
             * hidden
             * md:hidden
             * lg:hidden
             */
            case 'hidden':
                return `${prefix}${display}`;
            /**
             * sr-only
             * md:sr-only
             * lg:sr-only
             */
            case 'sr-only':
                return `${prefix}${display}`;
            /**
             * not-sr-only
             * md:not-sr-only
             * lg:not-sr-only
             */
            case 'not-sr-only':
                return `${prefix}${display}`;
            case 'visible':
                /**
                 * block
                 * md:block
                 * lg:block
                 * flex
                 * md:flex
                 * lg:flex
                 * inline-flex
                 * md:inline-flex
                 * lg:inline-flex
                 * grid
                 * md:grid
                 * lg:grid
                 * inline-grid
                 * md:inline-grid
                 * lg:inline-grid
                 */
                return `${prefix}${displayType}`;
            case 'unset':
            default:
                return '';
        }
    }

    getDisplayClasses(styles: IDisplayStyle, displayType: DisplayPropertyType = 'block'): string[] {
        const res = [];
        if (styles.display) res.push(this._getDisplayStyle(styles?.display, '', displayType));
        if (styles.displayTablet) res.push(this._getDisplayStyle(styles?.displayTablet, 'tablet', displayType));
        if (styles.displayDesktop) res.push(this._getDisplayStyle(styles?.displayDesktop, 'desktop', displayType));
        return res;
    }

    /* Spacing -------------------------------------------------------------- */
    private _getSpacingSuffix(spacing?: SpacingSizeOption, spacingType: SpacingType = 'm', place: SpacingPlace = 'x'): string {
        const base = `${spacingType}${place}-`;
        switch (spacing) {
            case 'left':
                return `ml-0`;
            case 'right':
                return `ml-auto`;
            case 'center':
                return `mx-auto`;
            case 'auto':
                return `${base}auto`;
            case 'xxs':
                return `${base}0.5`;
            case 'xs':
                return `${base}1`;
            case 's':
                return `${base}2`;
            case 'md':
                return `${base}3`;
            case 'l':
                return `${base}4`;
            case 'xl':
                return `${base}5`;
            case '2xl':
                return `${base}6`;
            case '3xl':
                return `${base}8`;
            case '4xl':
                return `${base}12`;
            case '5xl':
                return `${base}16`;
            case '6xl':
                return `${base}20`;
            case '7xl':
                return `${base}24`;
            case '8xl':
                return `${base}32`;
            case '9xl':
                return `${base}40`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getSpacingStyle(
        spacing?: SpacingSizeOption,
        spacingType: SpacingType = 'm',
        place: SpacingPlace = 'x',
        screenSize: ScreenSize = ''
    ): string {
        return `${this._getResponsivePrefix(screenSize)}${this._getSpacingSuffix(spacing, spacingType, place)}`;
    }

    getSpacingClasses(styles: ISpacingStyle): string[] {
        const res: string[] = [];
        for (const [key, value] of Object.entries(styles)) {
            if (!value || value === 'unset' || (!key.startsWith('margin') && !key.startsWith('padding'))) {
                continue;
            }

            /**
             * margins-right md:margins-right lg:margins-right
             * mx-auto md:mx-auto lg:mx-auto
             * ml-0 mr-0 ml-auto
             * md:ml-0 md:mr-0 md:ml-auto
             * lg:ml-0 lg:mr-0 lg:ml-auto
             *
             * my-auto mx-auto px-auto py-auto
             * md:my-auto md:mx-auto md:px-auto md:py-auto
             * lg:my-auto lg:mx-auto lg:px-auto lg:py-auto
             *
             * m-0.5 p-0.5
             * md:m-0.5 md:p-0.5
             * lg:m-0.5 lg:p-0.5
             * my-0.5 mx-0.5 px-0.5 py-0.5 ml-0.5 mr-0.5 mt-0.5 mb-0.5 pl-0.5 pr-0.5 pt-0.5 pb-0.5
             * md:my-0.5 md:mx-0.5 md:px-0.5 md:py-0.5 md:ml-0.5 md:mr-0.5 md:mt-0.5 md:mb-0.5 md:pl-0.5 md:pr-0.5 md:pt-0.5 md:pb-0.5
             * lg:my-0.5 lg:mx-0.5 lg:px-0.5 lg:py-0.5 lg:ml-0.5 lg:mr-0.5 lg:mt-0.5 lg:mb-0.5 lg:pl-0.5 lg:pr-0.5 lg:pt-0.5 lg:pb-0.5
             *
             * m-1 p-1
             * md:m-1 md:p-1
             * lg:m-1 lg:p-1
             * my-1 mx-1 px-1 py-1 ml-1 mr-1 mt-1 mb-1 pl-1 pr-1 pt-1 pb-1
             * md:my-1 md:mx-1 md:px-1 md:py-1 md:ml-1 md:mr-1 md:mt-1 md:mb-1 md:pl-1 md:pr-1 md:pt-1 md:pb-1
             * lg:my-1 lg:mx-1 lg:px-1 lg:py-1 lg:ml-1 lg:mr-1 lg:mt-1 lg:mb-1 lg:pl-1 lg:pr-1 lg:pt-1 lg:pb-1
             *
             * m-2 p-2
             * md:m-2 md:p-2
             * lg:m-2 lg:p-2
             * my-2 mx-2 px-2 py-2 ml-2 mr-2 mt-2 mb-2 pl-2 pr-2 pt-2 pb-2
             * md:my-2 md:mx-2 md:px-2 md:py-2 md:ml-2 md:mr-2 md:mt-2 md:mb-2 md:pl-2 md:pr-2 md:pt-2 md:pb-2
             * lg:my-2 lg:mx-2 lg:px-2 lg:py-2 lg:ml-2 lg:mr-2 lg:mt-2 lg:mb-2 lg:pl-2 lg:pr-2 lg:pt-2 lg:pb-2
             *
             * m-3 p-3
             * md:m-3 md:p-3
             * lg:m-3 lg:p-3
             * my-3 mx-3 px-3 py-3 ml-3 mr-3 mt-3 mb-3 pl-3 pr-3 pt-3 pb-3
             * md:my-3 md:mx-3 md:px-3 md:py-3 md:ml-3 md:mr-3 md:mt-3 md:mb-3 md:pl-3 md:pr-3 md:pt-3 md:pb-3
             * lg:my-3 lg:mx-3 lg:px-3 lg:py-3 lg:ml-3 lg:mr-3 lg:mt-3 lg:mb-3 lg:pl-3 lg:pr-3 lg:pt-3 lg:pb-3
             *
             * m-4 p-4
             * md:m-4 md:p-4
             * lg:m-4 lg:p-4
             * my-4 mx-4 px-4 py-4 ml-4 mr-4 mt-4 mb-4 pl-4 pr-4 pt-4 pb-4
             * md:my-4 md:mx-4 md:px-4 md:py-4 md:ml-4 md:mr-4 md:mt-4 md:mb-4 md:pl-4 md:pr-4 md:pt-4 md:pb-4
             * lg:my-4 lg:mx-4 lg:px-4 lg:py-4 lg:ml-4 lg:mr-4 lg:mt-4 lg:mb-4 lg:pl-4 lg:pr-4 lg:pt-4 lg:pb-4
             *
             * m-5 p-5
             * md:m-5 md:p-5
             * lg:m-5 lg:p-5
             * my-5 mx-5 px-5 py-5 ml-5 mr-5 mt-5 mb-5 pl-5 pr-5 pt-5 pb-5
             * md:my-5 md:mx-5 md:px-5 md:py-5 md:ml-5 md:mr-5 md:mt-5 md:mb-5 md:pl-5 md:pr-5 md:pt-5 md:pb-5
             * lg:my-5 lg:mx-5 lg:px-5 lg:py-5 lg:ml-5 lg:mr-5 lg:mt-5 lg:mb-5 lg:pl-5 lg:pr-5 lg:pt-5 lg:pb-5
             *
             * m-6 p-6
             * md:m-6 md:p-6
             * lg:m-6 lg:p-6
             * my-6 mx-6 px-6 py-6 ml-6 mr-6 mt-6 mb-6 pl-6 pr-6 pt-6 pb-6
             * md:my-6 md:mx-6 md:px-6 md:py-6 md:ml-6 md:mr-6 md:mt-6 md:mb-6 md:pl-6 md:pr-6 md:pt-6 md:pb-6
             * lg:my-6 lg:mx-6 lg:px-6 lg:py-6 lg:ml-6 lg:mr-6 lg:mt-6 lg:mb-6 lg:pl-6 lg:pr-6 lg:pt-6 lg:pb-6
             *
             * m-8 p-8
             * md:m-8 md:p-8
             * lg:m-8 lg:p-8
             * my-8 mx-8 px-8 py-8 ml-8 mr-8 mt-8 mb-8 pl-8 pr-8 pt-8 pb-8
             * md:my-8 md:mx-8 md:px-8 md:py-8 md:ml-8 md:mr-8 md:mt-8 md:mb-8 md:pl-8 md:pr-8 md:pt-8 md:pb-8
             * lg:my-8 lg:mx-8 lg:px-8 lg:py-8 lg:ml-8 lg:mr-8 lg:mt-8 lg:mb-8 lg:pl-8 lg:pr-8 lg:pt-8 lg:pb-8
             *
             * m-12 p-12
             * md:m-12 md:p-12
             * lg:m-12 lg:p-12
             * my-12 mx-12 px-12 py-12 ml-12 mr-12 mt-12 mb-12 pl-12 pr-12 pt-12 pb-12
             * md:my-12 md:mx-12 md:px-12 md:py-12 md:ml-12 md:mr-12 md:mt-12 md:mb-12 md:pl-12 md:pr-12 md:pt-12 md:pb-12
             * lg:my-12 lg:mx-12 lg:px-12 lg:py-12 lg:ml-12 lg:mr-12 lg:mt-12 lg:mb-12 lg:pl-12 lg:pr-12 lg:pt-12 lg:pb-12
             *
             * m-16 p-16
             * md:m-16 md:p-16
             * lg:m-16 lg:p-16
             * my-16 mx-16 px-16 py-16 ml-16 mr-16 mt-16 mb-16 pl-16 pr-16 pt-16 pb-16
             * md:my-16 md:mx-16 md:px-16 md:py-16 md:ml-16 md:mr-16 md:mt-16 md:mb-16 md:pl-16 md:pr-16 md:pt-16 md:pb-16
             * lg:my-16 lg:mx-16 lg:px-16 lg:py-16 lg:ml-16 lg:mr-16 lg:mt-16 lg:mb-16 lg:pl-16 lg:pr-16 lg:pt-16 lg:pb-16
             *
             * m-20 p-20
             * md:m-20 md:p-20
             * lg:m-20 lg:p-20
             * my-20 mx-20 px-20 py-20 ml-20 mr-20 mt-20 mb-20 pl-20 pr-20 pt-20 pb-20
             * md:my-20 md:mx-20 md:px-20 md:py-20 md:ml-20 md:mr-20 md:mt-20 md:mb-20 md:pl-20 md:pr-20 md:pt-20 md:pb-20
             * lg:my-20 lg:mx-20 lg:px-20 lg:py-20 lg:ml-20 lg:mr-20 lg:mt-20 lg:mb-20 lg:pl-20 lg:pr-20 lg:pt-20 lg:pb-20
             *
             * m-24 p-24
             * md:m-24 md:p-24
             * lg:m-24 lg:p-24
             * my-24 mx-24 px-24 py-24 ml-24 mr-24 mt-24 mb-24 pl-24 pr-24 pt-24 pb-24
             * md:my-24 md:mx-24 md:px-24 md:py-24 md:ml-24 md:mr-24 md:mt-24 md:mb-24 md:pl-24 md:pr-24 md:pt-24 md:pb-24
             * lg:my-24 lg:mx-24 lg:px-24 lg:py-24 lg:ml-24 lg:mr-24 lg:mt-24 lg:mb-24 lg:pl-24 lg:pr-24 lg:pt-24 lg:pb-24
             *
             * m-32 p-32
             * md:m-32 md:p-32
             * lg:m-32 lg:p-32
             * my-32 mx-32 px-32 py-32 ml-32 mr-32 mt-32 mb-32 pl-32 pr-32 pt-32 pb-32
             * md:my-32 md:mx-32 md:px-32 md:py-32 md:ml-32 md:mr-32 md:mt-32 md:mb-32 md:pl-32 md:pr-32 md:pt-32 md:pb-32
             * lg:my-32 lg:mx-32 lg:px-32 lg:py-32 lg:ml-32 lg:mr-32 lg:mt-32 lg:mb-32 lg:pl-32 lg:pr-32 lg:pt-32 lg:pb-32
             *
             * m-40 p-40
             * md:m-40 md:p-40
             * lg:m-40 lg:p-40
             * my-40 mx-40 px-40 py-40 ml-40 mr-40 mt-40 mb-40 pl-40 pr-40 pt-40 pb-40
             * md:my-40 md:mx-40 md:px-40 md:py-40 md:ml-40 md:mr-40 md:mt-40 md:mb-40 md:pl-40 md:pr-40 md:pt-40 md:pb-40
             * lg:my-40 lg:mx-40 lg:px-40 lg:py-40 lg:ml-40 lg:mr-40 lg:mt-40 lg:mb-40 lg:pl-40 lg:pr-40 lg:pt-40 lg:pb-40
             */
            const type: SpacingType = key.startsWith('margin') ? 'm' : 'p';
            const typeName = type === 'm' ? 'margin' : 'padding';
            let place: SpacingPlace;
            if (key.startsWith(typeName + 'X')) {
                place = 'x';
            } else if (key.startsWith(typeName + 'Y')) {
                place = 'y';
            } else if (key.startsWith(typeName + 'Left')) {
                place = 'l';
            } else if (key.startsWith(typeName + 'Right')) {
                place = 'r';
            } else if (key.startsWith(typeName + 'Top')) {
                place = 't';
            } else if (key.startsWith(typeName + 'Bottom')) {
                place = 'b';
            } else {
                place = '';
            }
            const screenSize: ScreenSize = key.endsWith('Tablet') ? 'tablet' : key.endsWith('Desktop') ? 'desktop' : '';
            res.push(this._getSpacingStyle(value, type, place, screenSize));
        }
        return res;
    }

    /* Size ----------------------------------------------------------------- */
    private _getSizeStyle(size: MaxWidthOption | AssetFixWidthOption, screenSize: ScreenSize = '', type: SizeType = 'max-w'): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (size) {
            case '25%':
                /**
                 * max-w-1/4 w-1/4 max-h-1/4 h-1/4
                 * md:max-w-1/4 md:w-1/4  md:max-h-1/4 md:h-1/4
                 * lg:max-w-1/4 lg:w-1/4 lg:max-h-1/4 lg:h-1/4
                 */
                return `${prefix}${type}-1/4`;
            case '50%':
                /**
                 * max-w-1/2 w-1/2 max-h-1/2 h-1/2
                 * md:max-w-1/2 md:w-1/2  md:max-h-1/2 md:h-1/2
                 * lg:max-w-1/2 lg:w-1/2 lg:max-h-1/2 lg:h-1/2
                 */
                return `${prefix}${type}-1/2`;
            case '100%':
                /**
                 * max-w-full w-full max-h-full h-full
                 * md:max-w-full md:w-full  md:max-h-full md:h-full
                 * lg:max-w-full lg:w-full lg:max-h-full lg:h-full
                 */
                return `${prefix}${type}-full`;
            case '1rem':
                /**
                 * max-w-4 w-4 max-h-4 h-4
                 * md:max-w-4 md:w-4  md:max-h-4 md:h-4
                 * lg:max-w-4 lg:w-4 lg:max-h-4 lg:h-4
                 */
                return `${prefix}${type}-4`;
            case '2rem':
                /**
                 * max-w-8 w-8 max-h-8 h-8
                 * md:max-w-8 md:w-8  md:max-h-8 md:h-8
                 * lg:max-w-8 lg:w-8 lg:max-h-8 lg:h-8
                 */
                return `${prefix}${type}-8`;
            case '3rem':
                /**
                 * max-w-12 w-12 max-h-12 h-12
                 * md:max-w-12 md:w-12  md:max-h-12 md:h-12
                 * lg:max-w-12 lg:w-12 lg:max-h-12 lg:h-12
                 */
                return `${prefix}${type}-12`;
            case '4rem':
                /**
                 * max-w-16 w-16 max-h-16 h-16
                 * md:max-w-16 md:w-16  md:max-h-16 md:h-16
                 * lg:max-w-16 lg:w-16 lg:max-h-16 lg:h-16
                 */
                return `${prefix}${type}-16`;
            case '5rem':
                /**
                 * max-w-20 w-20 max-h-20 h-20
                 * md:max-w-20 md:w-20  md:max-h-20 md:h-20
                 * lg:max-w-20 lg:w-20 lg:max-h-20 lg:h-20
                 */
                return `${prefix}${type}-20`;
            case '6rem':
                /**
                 * max-w-24 w-24 max-h-24 h-24
                 * md:max-w-24 md:w-24  md:max-h-24 md:h-24
                 * lg:max-w-24 lg:w-24 lg:max-h-24 lg:h-24
                 */
                return `${prefix}${type}-24`;
            case '7rem':
                /**
                 * max-w-28 w-28 max-h-28 h-28
                 * md:max-w-28 md:w-28  md:max-h-28 md:h-28
                 * lg:max-w-28 lg:w-28 lg:max-h-28 lg:h-28
                 */
                return `${prefix}${type}-28`;
            case '8rem':
                /**
                 * max-w-32 w-32 max-h-32 h-32
                 * md:max-w-32 md:w-32  md:max-h-32 md:h-32
                 * lg:max-w-32 lg:w-32 lg:max-h-32 lg:h-32
                 */
                return `${prefix}${type}-32`;
            case '9rem':
                /**
                 * max-w-36 w-36 max-h-36 h-36
                 * md:max-w-36 md:w-36  md:max-h-36 md:h-36
                 * lg:max-w-36 lg:w-36 lg:max-h-36 lg:h-36
                 */
                return `${prefix}${type}-36`;
            case '10rem':
                /**
                 * max-w-40 w-40 max-h-40 h-40
                 * md:max-w-40 md:w-40  md:max-h-40 md:h-40
                 * lg:max-w-40 lg:w-40 lg:max-h-40 lg:h-40
                 */
                return `${prefix}${type}-40`;
            case '11rem':
                /**
                 * max-w-44 w-44 max-h-44 h-44
                 * md:max-w-44 md:w-44  md:max-h-44 md:h-44
                 * lg:max-w-44 lg:w-44 lg:max-h-44 lg:h-44
                 */
                return `${prefix}${type}-44`;
            case '12rem':
                /**
                 * max-w-48 w-48 max-h-48 h-48
                 * md:max-w-48 md:w-48  md:max-h-48 md:h-48
                 * lg:max-w-48 lg:w-48 lg:max-h-48 lg:h-48
                 */
                return `${prefix}${type}-48`;
            case '14rem':
                /**
                 * max-w-56 w-56 max-h-56 h-56
                 * md:max-w-56 md:w-56  md:max-h-56 md:h-56
                 * lg:max-w-56 lg:w-56 lg:max-h-56 lg:h-56
                 */
                return `${prefix}${type}-56`;
            case '16rem':
                /**
                 * max-w-64 w-64 max-h-64 h-64
                 * md:max-w-64 md:w-64  md:max-h-64 md:h-64
                 * lg:max-w-64 lg:w-64 lg:max-h-64 lg:h-64
                 */
                return `${prefix}${type}-64`;
            case '18rem':
                /**
                 * max-w-72 w-72 max-h-72 h-72
                 * md:max-w-72 md:w-72  md:max-h-72 md:h-72
                 * lg:max-w-72 lg:w-72 lg:max-h-72 lg:h-72
                 */
                return `${prefix}${type}-72`;
            case '20rem':
                /**
                 * max-w-xs w-xs max-h-xs h-xs
                 * md:max-w-xs md:w-xs  md:max-h-xs md:h-xs
                 * lg:max-w-xs lg:w-xs lg:max-h-xs lg:h-xs
                 */
                return `${prefix}${type}-xs`;
            case '24rem':
                /**
                 * max-w-sm w-sm max-h-sm h-sm
                 * md:max-w-sm md:w-sm  md:max-h-sm md:h-sm
                 * lg:max-w-sm lg:w-sm lg:max-h-sm lg:h-sm
                 */
                return `${prefix}${type}-sm`;
            case '28rem':
                /**
                 * max-w-md w-md max-h-md h-md
                 * md:max-w-md md:w-md  md:max-h-md md:h-md
                 * lg:max-w-md lg:w-md lg:max-h-md lg:h-md
                 */
                return `${prefix}${type}-md`;
            case '32rem':
                /**
                 * max-w-lg w-lg max-h-lg h-lg
                 * md:max-w-lg md:w-lg  md:max-h-lg md:h-lg
                 * lg:max-w-lg lg:w-lg lg:max-h-lg lg:h-lg
                 */
                return `${prefix}${type}-lg`;
            case '36rem':
                /**
                 * max-w-xl w-xl max-h-xl h-xl
                 * md:max-w-xl md:w-xl  md:max-h-xl md:h-xl
                 * lg:max-w-xl lg:w-xl lg:max-h-xl lg:h-xl
                 */
                return `${prefix}${type}-xl`;
            case '42rem':
                /**
                 * max-w-2xl w-2xl max-h-2xl h-2xl
                 * md:max-w-2xl md:w-2xl  md:max-h-2xl md:h-2xl
                 * lg:max-w-2xl lg:w-2xl lg:max-h-2xl lg:h-2xl
                 */
                return `${prefix}${type}-2xl`;
            case '48rem':
                /**
                 * max-w-3xl w-3xl max-h-3xl h-3xl
                 * md:max-w-3xl md:w-3xl  md:max-h-3xl md:h-3xl
                 * lg:max-w-3xl lg:w-3xl lg:max-h-3xl lg:h-3xl
                 */
                return `${prefix}${type}-3xl`;
            case '56rem':
                /**
                 * max-w-4xl w-4xl max-h-4xl h-4xl
                 * md:max-w-4xl md:w-4xl  md:max-h-4xl md:h-4xl
                 * lg:max-w-4xl lg:w-4xl lg:max-h-4xl lg:h-4xl
                 */
                return `${prefix}${type}-4xl`;
            case '64rem':
                /**
                 * max-w-5xl w-5xl max-h-5xl h-5xl
                 * md:max-w-5xl md:w-5xl  md:max-h-5xl md:h-5xl
                 * lg:max-w-5xl lg:w-5xl lg:max-h-5xl lg:h-5xl
                 */
                return `${prefix}${type}-5xl`;
            case '72rem':
                /**
                 * max-w-6xl w-6xl max-h-6xl h-6xl
                 * md:max-w-6xl md:w-6xl  md:max-h-6xl md:h-6xl
                 * lg:max-w-6xl lg:w-6xl lg:max-h-6xl lg:h-6xl
                 */
                return `${prefix}${type}-6xl`;
            case '80rem':
                /**
                 * max-w-7xl w-7xl max-h-7xl h-7xl
                 * md:max-w-7xl md:w-7xl  md:max-h-7xl md:h-7xl
                 * lg:max-w-7xl lg:w-7xl lg:max-h-7xl lg:h-7xl
                 */
                return `${prefix}${type}-7xl`;
            case '90rem':
                /**
                 * max-w-[90rem] w-[90rem] max-h-[90rem] h-[90rem]
                 * md:max-w-[90rem] md:w-[90rem]  md:max-h-[90rem] md:h-[90rem]
                 * lg:max-w-[90rem] lg:w-[90rem] lg:max-h-[90rem] lg:h-[90rem]
                 */
                return `${prefix}${type}-[90rem]`;
            case 'unset':
            default:
                return '';
        }
    }

    getSizeClasses(styles: ISizeStyle): string[] {
        const res: string[] = [];
        if (styles.maxWidth) res.push(this._getSizeStyle(styles.maxWidth));
        if (styles.maxWidthTablet) res.push(this._getSizeStyle(styles.maxWidthTablet, 'tablet'));
        if (styles.maxWidthDesktop) res.push(this._getSizeStyle(styles.maxWidthDesktop, 'desktop'));

        if (styles.height) res.push(this._getSizeStyle(styles.height, '', 'h'));
        if (styles.heightTablet) res.push(this._getSizeStyle(styles.heightTablet, 'tablet', 'h'));
        if (styles.heightDesktop) res.push(this._getSizeStyle(styles.heightDesktop, 'desktop', 'h'));

        if (styles.width) res.push(this._getSizeStyle(styles.width, '', 'w'));
        if (styles.widthTablet) res.push(this._getSizeStyle(styles.widthTablet, 'tablet', 'w'));
        if (styles.widthDesktop) res.push(this._getSizeStyle(styles.widthDesktop, 'desktop', 'w'));

        return res;
    }

    /* Typography ----------------------------------------------------------- */
    private _getColorStyle(color: TextColorOption): string {
        switch (color) {
            case 'primary':
                /**
                 * text-primary
                 * md:text-primary
                 * lg:text-primary
                 * */
                return `text-primary`;
            case 'secondary':
                /**
                 * text-secondary
                 * md:text-secondary
                 * lg:text-secondary
                 * */
                return `text-secondary`;
            case 'accent':
                /**
                 * text-accent
                 * md:text-accent
                 * lg:text-accent
                 * */
                return `text-accent`;
            case 'accent-light':
                /**
                 * text-accent-light
                 * md:text-accent-light
                 * lg:text-accent-light
                 * */
                return `text-accent-light`;
            case 'accent-dark':
                /**
                 * text-accent-dark
                 * md:text-accent-dark
                 * lg:text-accent-dark
                 * */
                return `text-accent-dark`;
            case 'grey-dark':
                /**
                 * text-grey-dark
                 * md:text-grey-dark
                 * lg:text-grey-dark
                 * */
                return `text-grey-dark`;
            case 'grey-light':
                /**
                 * text-grey-light
                 * md:text-grey-light
                 * lg:text-grey-light
                 * */
                return `text-grey-light`;
            case 'white':
                /**
                 * text-white
                 * md:text-white
                 * lg:text-white
                 * */
                return `text-white`;
            case 'success':
                /**
                 * text-success
                 * md:text-success
                 * lg:text-md:text-success
                 * */
                return `text-success`;
            case 'warning':
                /**
                 * text-warning
                 * md:text-warning
                 * lg:text-md:text-warning
                 * */
                return `text-warning`;
            case 'error':
                /**
                 * text-error
                 * md:text-error
                 * lg:text-md:text-error
                 * */
                return `text-error`;
            case 'inherit':
                /**
                 * text-inherit
                 * md:text-inherit
                 * lg:text-inherit
                 * */
                return `text-inherit`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getFontWeightStyle(fontWeight: FontWeightOption): string {
        switch (fontWeight) {
            case 'thin':
                /**
                 * font-thin
                 * md:font-thin
                 * lg:font-thin
                 * */
                return `font-thin`;
            case 'extralight':
                /**
                 * font-extralight
                 * md:font-extralight
                 * lg:font-extralight
                 * */
                return `font-extralight`;
            case 'light':
                /**
                 * font-light
                 * md:font-light
                 * lg:font-light
                 * */
                return `font-light`;
            case 'normal':
                /**
                 * font-normal
                 * md:font-normal
                 * lg:font-normal
                 * */
                return `font-normal`;
            case 'medium':
                /**
                 * font-medium
                 * md:font-medium
                 * lg:font-medium
                 * */
                return `font-medium`;
            case 'semibold':
                /**
                 * font-semibold
                 * md:font-semibold
                 * lg:font-semibold
                 * */
                return `font-semibold`;
            case 'bold':
                /**
                 * font-bold
                 * md:font-bold
                 * lg:font-bold
                 * */
                return `font-bold`;
            case 'extrabold':
                /**
                 * font-extrabold
                 * md:font-extrabold
                 * lg:font-extrabold
                 * */
                return `font-extrabold`;
            case 'black':
                /**
                 * font-black
                 * md:font-black
                 * lg:font-black
                 * */
                return `font-black`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getFontSizeStyle(fontSize: FontSizeOption): string {
        switch (fontSize) {
            case 'xs':
                /**
                 * text-xs
                 * md:text-xs
                 * lg:text-xs
                 * */
                return `text-xs`;
            case 'sm':
                /**
                 * text-sm
                 * md:text-sm
                 * lg:text-sm
                 * */
                return `text-sm`;
            case 'base':
                /**
                 * text-base
                 * md:text-base
                 * lg:text-base
                 * */
                return `text-base`;
            case 'lg':
                /**
                 * text-lg
                 * md:text-lg
                 * lg:text-lg
                 * */
                return `text-lg`;
            case 'xl':
                /**
                 * text-xl
                 * md:text-xl
                 * lg:text-xl
                 * */
                return `text-xl`;
            case '2xl':
                /**
                 * text-2xl
                 * md:text-2xl
                 * lg:text-2xl
                 * */
                return `text-2xl`;
            case '3xl':
                /**
                 * text-3xl
                 * md:text-3xl
                 * lg:text-3xl
                 * */
                return `text-3xl`;
            case '4xl':
                /**
                 * text-4xl
                 * md:text-4xl
                 * lg:text-4xl
                 * */
                return `text-4xl`;
            case '5xl':
                /**
                 * text-5xl
                 * md:text-5xl
                 * lg:text-5xl
                 * */
                return `text-5xl`;
            case '6xl':
                /**
                 * text-6xl
                 * md:text-6xl
                 * lg:text-6xl
                 * */
                return `text-6xl`;
            case '7xl':
                /**
                 * text-7xl
                 * md:text-7xl
                 * lg:text-7xl
                 * */
                return `text-7xl`;
            case '8xl':
                /**
                 * text-8xl
                 * md:text-8xl
                 * lg:text-8xl
                 * */
                return `text-8xl`;
            case '9xl':
                /**
                 * text-9xl
                 * md:text-9xl
                 * lg:text-9xl
                 * */
                return `text-9xl`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getTextAlignStyle(textAlign: TextAlignOption): string {
        switch (textAlign) {
            case 'left':
                /**
                 * text-left
                 * md:text-left
                 * lg:text-left
                 * */
                return `text-left`;
            case 'center':
                /**
                 * text-center
                 * md:text-center
                 * lg:text-center
                 * */
                return `text-center`;
            case 'right':
                /**
                 * text-right
                 * md:text-right
                 * lg:text-right
                 * */
                return `text-right`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getTextTransformStyle(textTransform: TextTransformOption): string {
        switch (textTransform) {
            case 'uppercase':
                /**
                 * uppercase
                 * md:uppercase
                 * lg:uppercase
                 */
                return `uppercase`;
            case 'lowercase':
                /**
                 * lowercase
                 * md:lowercase
                 * lg:lowercase
                 */
                return `lowercase`;
            case 'capitalize':
                /**
                 * capitalize
                 * md:capitalize
                 * lg:capitalize
                 */
                return `capitalize`;
            case 'normal-case':
                /**
                 * normal-case
                 * md:normal-case
                 * lg:normal-case
                 */
                return `normal-case`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getLetterSpacingStyle(letterSpacing: LetterSpacingOption): string {
        switch (letterSpacing) {
            case 'tighter':
                /**
                 * tracking-tighter
                 * md:tracking-tighter
                 * lg:tracking-tighter
                 * */
                return `tracking-tighter`;
            case 'tight':
                /**
                 * tracking-tight
                 * md:tracking-tight
                 * lg:tracking-tight
                 * */
                return `tracking-tight`;
            case 'normal':
                /**
                 * tracking-normal
                 * md:tracking-normal
                 * lg:tracking-normal
                 * */
                return `tracking-normal`;
            case 'wide':
                /**
                 * tracking-wide
                 * md:tracking-wide
                 * lg:tracking-wide
                 * */
                return `tracking-wide`;
            case 'wider':
                /**
                 * tracking-wider
                 * md:tracking-wider
                 * lg:tracking-wider
                 * */
                return `tracking-wider`;
            case 'widest':
                /**
                 * tracking-widest
                 * md:tracking-widest
                 * lg:tracking-widest
                 * */
                return `tracking-widest`;
            case 'unset':
            default:
                return '';
        }
    }

    /** generates the talc style class - takes in account the deprecated `'italic' | 'normal' | 'unset'` options  */
    private _getItalicStyle(italic: boolean | 'italic' | 'normal' | 'unset'): string {
        switch (italic) {
            case true:
            case 'normal':
                /**
                 * italic
                 * md:italic
                 * lg:italic
                 * */
                return `italic`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getTypographyStyle<T extends TypographyProperty>(
        property: T,
        typo: TypographyPropertyValue<T>,
        screenSize: ScreenSize = ''
    ): string {
        const prefix = this._getResponsivePrefix(screenSize);
        if (typo && typo !== 'unset') {
            switch (property) {
                case 'color':
                    return `${prefix}${this._getColorStyle(typo as TextColorOption)}`;
                case 'fontWeight':
                    return `${prefix}${this._getFontWeightStyle(typo as FontWeightOption)}`;
                case 'fontSize':
                    return `${prefix}${this._getFontSizeStyle(typo as FontSizeOption)}`;
                case 'textAlign':
                    return `${prefix}${this._getTextAlignStyle(typo as TextAlignOption)}`;
                case 'italic':
                    return `${prefix}${this._getItalicStyle(typo as boolean | 'italic' | 'normal' | 'unset')}`;
                case 'textTransform':
                    return `${prefix}${this._getTextTransformStyle(typo as TextTransformOption)}`;
                case 'letterSpacing':
                    return `${prefix}${this._getLetterSpacingStyle(typo as LetterSpacingOption)}`;
            }
        }
        return '';
    }

    getTypographyClasses(styles: ITypographyStyle): string[] {
        const res: string[] = [];
        if (styles.color) res.push(this._getTypographyStyle('color', styles.color));
        if (styles.colorTablet) res.push(this._getTypographyStyle('color', styles.colorTablet, 'tablet'));
        if (styles.colorDesktop) res.push(this._getTypographyStyle('color', styles.colorDesktop, 'desktop'));

        if (styles.fontSize) res.push(this._getTypographyStyle('fontSize', styles.fontSize));
        if (styles.fontSizeTablet) res.push(this._getTypographyStyle('fontSize', styles.fontSizeTablet, 'tablet'));
        if (styles.fontSizeDesktop) res.push(this._getTypographyStyle('fontSize', styles.fontSizeDesktop, 'desktop'));

        if (styles.textAlign) res.push(this._getTypographyStyle('textAlign', styles.textAlign));
        if (styles.textAlignTablet) res.push(this._getTypographyStyle('textAlign', styles.textAlignTablet, 'tablet'));
        if (styles.textAlignDesktop) res.push(this._getTypographyStyle('textAlign', styles.textAlignDesktop, 'desktop'));

        if (styles.fontWeight) res.push(this._getTypographyStyle('fontWeight', styles.fontWeight));
        if (styles.fontWeightTablet) res.push(this._getTypographyStyle('fontWeight', styles.fontWeightTablet), 'tablet');
        if (styles.fontWeightDesktop) res.push(this._getTypographyStyle('fontWeight', styles.fontWeightDesktop), 'desktop');

        if (styles.italic) res.push(this._getTypographyStyle('italic', styles.italic));
        if (styles.italicTablet) res.push(this._getTypographyStyle('italic', styles.italicTablet, 'tablet'));
        if (styles.italicDesktop) res.push(this._getTypographyStyle('italic', styles.italicDesktop, 'desktop'));

        if (styles.textTransform) res.push(this._getTypographyStyle('textTransform', styles.textTransform));
        if (styles.textTransformTablet) res.push(this._getTypographyStyle('textTransform', styles.textTransformTablet, 'tablet'));
        if (styles.textTransformDesktop) res.push(this._getTypographyStyle('textTransform', styles.textTransformDesktop, 'desktop'));

        if (styles.letterSpacing) res.push(this._getTypographyStyle('letterSpacing', styles.letterSpacing));
        if (styles.letterSpacingTablet) res.push(this._getTypographyStyle('letterSpacing', styles.letterSpacingTablet, 'tablet'));
        if (styles.letterSpacingDesktop) res.push(this._getTypographyStyle('letterSpacing', styles.letterSpacingDesktop, 'desktop'));
        return res;
    }

    /* Border Radius -------------------------------------------------------- */
    private _getBorderRadiusStyle(radius: BorderRadiusOption, screenSize: ScreenSize = ''): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (radius) {
            case 'none':
                /**
                 * rounded-none
                 * md:rounded-none
                 * lg:rounded-none
                 */
                return prefix + 'rounded-none';
            case 'sm':
                /**
                 * rounded-sm
                 * md:rounded-sm
                 * lg:rounded-sm
                 */
                return prefix + 'rounded-sm';
            case 'md':
                /**
                 * rounded-md
                 * md:rounded-md
                 * lg:rounded-md
                 */
                return prefix + 'rounded-md';
            case 'lg':
                /**
                 * rounded-lg
                 * md:rounded-lg
                 * lg:rounded-lg
                 */
                return prefix + 'rounded-lg';
            case 'xl':
                /**
                 * rounded-xl
                 * md:rounded-xl
                 * lg:rounded-xl
                 */
                return prefix + 'rounded-xl';
            case '2xl':
                /**
                 * rounded-2xl
                 * md:rounded-2xl
                 * lg:rounded-2xl
                 */
                return prefix + 'rounded-2xl';
            case '3xl':
                /**
                 * rounded-3xl
                 * md:rounded-3xl
                 * lg:rounded-3xl
                 */
                return prefix + 'rounded-3xl';
            case 'full':
                /**
                 * rounded-full
                 * md:rounded-full
                 * lg:rounded-full
                 */
                return prefix + 'rounded-full';
            case 'unset':
            default:
                return '';
        }
    }

    getBorderRadiusClasses(styles: IBorderRadiusStyle): string[] {
        const res: string[] = [];
        if (styles.radius) res.push(this._getBorderRadiusStyle(styles.radius));
        if (styles.radiusTablet) res.push(this._getBorderRadiusStyle(styles.radiusTablet, 'tablet'));
        if (styles.radiusDesktop) res.push(this._getBorderRadiusStyle(styles.radiusDesktop, 'desktop'));

        if (styles.borderRadius) res.push(this._getBorderRadiusStyle(styles.borderRadius));
        if (styles.borderRadiusTablet) res.push(this._getBorderRadiusStyle(styles.borderRadiusTablet, 'tablet'));
        if (styles.borderRadiusDesktop) res.push(this._getBorderRadiusStyle(styles.borderRadiusDesktop, 'desktop'));
        return res;
    }

    /* Box Shadow ----------------------------------------------------------------- */
    private _getBoxShadowStyle(option: BoxShadowOption, screenSize: ScreenSize = ''): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (option) {
            case 'unset':
                return '';
            case 'sm':
                /**
                 * shadow-sm
                 * md:shadow-sm
                 * lg:shadow-sm
                 */
                return `${prefix}shadow-sm`;
            case 'normal':
                /**
                 * shadow
                 * md:shadow
                 * lg:shadow
                 */
                return `${prefix}shadow`;
            case 'md':
                /**
                 * shadow-md
                 * md:shadow-md
                 * lg:shadow-md
                 */
                return `${prefix}shadow-md`;
            case 'lg':
                /**
                 * shadow-lg
                 * md:shadow-lg
                 * lg:shadow-lg
                 */
                return `${prefix}shadow-lg`;
            case 'xl':
                /**
                 * shadow-xl
                 * md:shadow-xl
                 * lg:shadow-xl
                 */
                return `${prefix}shadow-xl`;
            case '2xl':
                /**
                 * shadow-2xl
                 * md:shadow-2xl
                 * lg:shadow-2xl
                 */
                return `${prefix}shadow-2xl`;
            case 'inner':
                /**
                 * shadow-inner
                 * md:shadow-inner
                 * lg:shadow-inner
                 */
                return `${prefix}shadow-inner`;
            case 'none':
                /**
                 * shadow-none
                 * md:shadow-none
                 * lg:shadow-none
                 */
                return `${prefix}shadow-none`;
            // case 'tarif-mobile-button':
            //     /**
            //      * shadow-tarif-mobile-button
            //      * md:shadow-tarif-mobile-button
            //      * lg:shadow-tarif-mobile-button
            //      */
            //     return `${prefix}shadow-tarif-mobile-button`;
            // case 'checkout-iframe':
            //     /**
            //      * shadow-checkout-iframe
            //      * md:shadow-checkout-iframe
            //      * lg:shadow-checkout-iframe
            //      */
            //     return `${prefix}shadow-checkout-iframe`;
            default:
                return '';
        }
    }

    getBoxShadowClasses(styles: IBoxShadowStyles): string[] {
        const res: string[] = [];
        if (styles.shadow) res.push(this._getBoxShadowStyle(styles.shadow));
        if (styles.shadowTablet) res.push(this._getBoxShadowStyle(styles.shadowTablet, 'tablet'));
        if (styles.shadowDesktop) res.push(this._getBoxShadowStyle(styles.shadowDesktop, 'desktop'));
        return res;
    }

    /* Col Span ------------------------------------------------------------- */
    private _getColSpanStyle(colSpan: ColSpanOption, screenSize: ScreenSize = ''): string {
        /**
         * col-span-1 md:col-span-1 lg:col-span-1
         * col-span-2 md:col-span-2 lg:col-span-2
         * col-span-3 md:col-span-3 lg:col-span-3
         * col-span-4 md:col-span-4 lg:col-span-4
         * col-span-5 md:col-span-5 lg:col-span-5
         * col-span-6 md:col-span-6 lg:col-span-6
         * col-span-7 md:col-span-7 lg:col-span-7
         * col-span-8 md:col-span-8 lg:col-span-8
         * col-span-9 md:col-span-9 lg:col-span-9
         * col-span-10 md:col-span-10 lg:col-span-10
         * col-span-11 md:col-span-11 lg:col-span-11
         * col-span-12 md:col-span-12 lg:col-span-12
         */
        return `${this._getResponsivePrefix(screenSize)}col-span-${colSpan}`;
    }

    getColSpanClasses(styles: IColSpanSingleStyle): string[] {
        const res: string[] = [];
        if (styles.colSpan) res.push(this._getColSpanStyle(styles.colSpan));
        if (styles.colSpanTablet) res.push(this._getColSpanStyle(styles.colSpanTablet, 'tablet'));
        if (styles.colSpanDesktop) res.push(this._getColSpanStyle(styles.colSpanDesktop, 'desktop'));
        return res;
    }

    /* Gap ------------------------------------------------------------------ */
    private _getGapStyle(gap: GapSizeOption, orientation: 'x' | 'y' | '' = 'x', screenSize: ScreenSize = ''): string {
        const base = `${this._getResponsivePrefix(screenSize)}gap${orientation ? '-' + orientation : ''}`;
        switch (gap) {
            case 'xxs':
                /**
                 * gap-0.5 gap-x-0.5 gap-y-0.5
                 * md:gap-0.5 md:gap-x-0.5 md:gap-y-0.5
                 * lg:gap-0.5 lg:gap-x-0.5 lg:gap-y-0.5
                 * */
                return `${base}-0.5`;
            case 'xs':
                /**
                 * gap-1 gap-x-1 gap-y-1
                 * md:gap-1 md:gap-x-1 md:gap-y-1
                 * lg:gap-1 lg:gap-x-1 lg:gap-y-1
                 * */
                return `${base}-1`;
            case 's':
                /**
                 * gap-2 gap-x-2 gap-y-2
                 * md:gap-2 md:gap-x-2 md:gap-y-2
                 * lg:gap-2 lg:gap-x-2 lg:gap-y-2
                 * */
                return `${base}-2`;
            case 'md':
                /**
                 * gap-4 gap-x-4 gap-y-4
                 * md:gap-4 md:gap-x-4 md:gap-y-4
                 * lg:gap-4 lg:gap-x-4 lg:gap-y-4
                 * */
                return `${base}-4`;
            case 'l':
                /**
                 * gap-5 gap-x-5 gap-y-5
                 * md:gap-5 md:gap-x-5 md:gap-y-5
                 * lg:gap-5 lg:gap-x-5 lg:gap-y-5
                 * */
                return `${base}-5`;
            case 'xl':
                /**
                 * gap-6 gap-x-6 gap-y-6
                 * md:gap-6 md:gap-x-6 md:gap-y-6
                 * lg:gap-6 lg:gap-x-6 lg:gap-y-6
                 * */
                return `${base}-6`;
            case '2xl':
                /**
                 * gap-7 gap-x-7 gap-y-7
                 * md:gap-7 md:gap-x-7 md:gap-y-7
                 * lg:gap-7 lg:gap-x-7 lg:gap-y-7
                 * */
                return `${base}-7`;
            case '3xl':
                /**
                 * gap-8 gap-x-8 gap-y-8
                 * md:gap-8 md:gap-x-8 md:gap-y-8
                 * lg:gap-8 lg:gap-x-8 lg:gap-y-8
                 * */
                return `${base}-8`;
            case '4xl':
                /**
                 * gap-9 gap-x-9 gap-y-9
                 * md:gap-9 md:gap-x-9 md:gap-y-9
                 * lg:gap-9 lg:gap-x-9 lg:gap-y-9
                 * */
                return `${base}-9`;
            case '5xl':
                /**
                 * gap-10 gap-x-10 gap-y-10
                 * md:gap-10 md:gap-x-10 md:gap-y-10
                 * lg:gap-10 lg:gap-x-10 lg:gap-y-10
                 * */
                return `${base}-10`;
            case '6xl':
                /**
                 * gap-11 gap-x-11 gap-y-11
                 * md:gap-11 md:gap-x-11 md:gap-y-11
                 * lg:gap-11 lg:gap-x-11 lg:gap-y-11
                 * */
                return `${base}-11`;
            case '7xl':
                /**
                 * gap-12 gap-x-12 gap-y-12
                 * md:gap-12 md:gap-x-12 md:gap-y-12
                 * lg:gap-12 lg:gap-x-12 lg:gap-y-12
                 * */
                return `${base}-12`;
            case '8xl':
                /**
                 * gap-13 gap-x-13 gap-y-13
                 * md:gap-13 md:gap-x-13 md:gap-y-13
                 * lg:gap-13 lg:gap-x-13 lg:gap-y-13
                 * */
                return `${base}-13`;
            case '9xl':
                /**
                 * gap-14 gap-x-14 gap-y-14
                 * md:gap-14 md:gap-x-14 md:gap-y-14
                 * lg:gap-14 lg:gap-x-14 lg:gap-y-14
                 * */
                return `${base}-14`;
            case 'none':
                /**
                 * gap-0 gap-x-0 gap-y-0
                 * md:gap-0 md:gap-x-0 md:gap-y-0
                 * lg:gap-0 lg:gap-x-0 lg:gap-y-0
                 * */
                return `${base}-0`;
            default:
                return '';
        }
    }

    getGapClasses(styles: IGapStyle): string[] {
        const res: string[] = [];
        if (styles.gap) res.push(this._getGapStyle(styles.gap, ''));
        if (styles.gapTablet) res.push(this._getGapStyle(styles.gapTablet, '', 'tablet'));
        if (styles.gapDesktop) res.push(this._getGapStyle(styles.gapDesktop, '', 'desktop'));

        if (styles.gapX) res.push(this._getGapStyle(styles.gapX));
        if (styles.gapXTablet) res.push(this._getGapStyle(styles.gapXTablet, 'x', 'tablet'));
        if (styles.gapXDesktop) res.push(this._getGapStyle(styles.gapXDesktop, 'x', 'desktop'));

        if (styles.gapY) res.push(this._getGapStyle(styles.gapY, 'y'));
        if (styles.gapYTablet) res.push(this._getGapStyle(styles.gapYTablet, 'y', 'tablet'));
        if (styles.gapYDesktop) res.push(this._getGapStyle(styles.gapYDesktop, 'y', 'desktop'));
        return res;
    }

    /* Flex ------------------------------------------------------------------ */
    private _getFlexDirectionStyle(direction?: FlexDirectionOption, screenSize: ScreenSize = ''): string {
        const base = this._getResponsivePrefix(screenSize);
        switch (direction) {
            case 'unset':
                return '';
            case 'row':
                /**
                 * flex-row
                 * md:flex-row
                 * lg:flex-row
                 */
                return `${base}flex-row`;
            case 'row-reverse':
                /**
                 * flex-row-reverse
                 * md:flex-row-reverse
                 * lg:flex-row-reverse
                 */
                return `${base}flex-row-reverse`;
            case 'column':
                /**
                 * flex-col
                 * md:flex-col
                 * lg:flex-col
                 */
                return `${base}flex-col`;
            case 'column-reverse':
                /**
                 * flex-col-reverse
                 * md:flex-col-reverse
                 * lg:flex-col-reverse
                 */
                return `${base}flex-col-reverse`;
            default:
                return '';
        }
    }

    private _getFlexAlignStyle(align?: FlexAlignOption, screenSize: ScreenSize = ''): string {
        const base = this._getResponsivePrefix(screenSize);
        switch (align) {
            case 'unset':
                return '';
            case 'flex-start':
                /**
                 * items-start
                 * md:items-start
                 * lg:items-start
                 */
                return `${base}items-start`;
            case 'center':
                /**
                 * items-center
                 * md:items-center
                 * lg:items-center
                 */
                return `${base}items-center`;
            case 'flex-end':
                /**
                 * items-end
                 * md:items-end
                 * lg:items-end
                 */
                return `${base}items-end`;
            case 'baseline':
                /**
                 * items-baseline
                 * md:items-baseline
                 * lg:items-baseline
                 */
                return `${base}items-baseline`;
            case 'stretch':
                /**
                 * items-stretch
                 * md:items-stretch
                 * lg:items-stretch
                 */
                return `${base}items-stretch`;
            default:
                return '';
        }
    }

    private _getFlexJustifyStyle(justify?: FlexJustifyOption, screenSize: ScreenSize = ''): string {
        const base = this._getResponsivePrefix(screenSize);
        switch (justify) {
            case 'unset':
                return '';
            case 'flex-start':
                /**
                 * justify-start
                 * md:justify-start
                 * lg:justify-start
                 */
                return `${base}justify-start`;
            case 'center':
                /**
                 * justify-center
                 * md:justify-center
                 * lg:justify-center
                 */
                return `${base}justify-center`;
            case 'flex-end':
                /**
                 * justify-end
                 * md:justify-end
                 * lg:justify-end
                 */
                return `${base}justify-end`;
            case 'space-between':
                /**
                 * justify-between
                 * md:justify-between
                 * lg:justify-between
                 */
                return `${base}justify-between`;
            case 'space-around':
                /**
                 * justify-around
                 * md:justify-around
                 * lg:justify-around
                 */
                return `${base}justify-around`;
            case 'space-evenly':
                /**
                 * justify-evenly
                 * md:justify-evenly
                 * lg:justify-evenly
                 */
                return `${base}justify-evenly`;
            default:
                return '';
        }
    }

    private _getFlexWrapStyle(wrap?: FlexWrapOption, screenSize: ScreenSize = ''): string {
        const base = this._getResponsivePrefix(screenSize);
        switch (wrap) {
            case 'unset':
                return '';
            case 'wrap':
                /**
                 * flex-wrap
                 * md:flex-wrap
                 * lg:flex-wrap
                 */
                return `${base}flex-wrap`;
            case 'wrap-reverse':
                /**
                 * flex-wrap-reverse
                 * md:flex-wrap-reverse
                 * lg:flex-wrap-reverse
                 */
                return `${base}flex-wrap-reverse`;
            case 'nowrap':
                /**
                 * flex-nowrap
                 * md:flex-nowrap
                 * lg:flex-nowrap
                 */
                return `${base}flex-nowrap`;
            default:
                return '';
        }
    }

    // TODO: Check if they are different from grid gaps. Maybe 2 different functions are not needed.

    private _getFlexGapStyle(gap?: GapSizeOption, screenSize: ScreenSize = ''): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (gap) {
            case 'xxs':
                /**
                 * gap-0.5
                 * md:gap-0.5
                 * lg:gap-0.5
                 */
                return `${prefix}gap-0.5`;
            case 'xs':
                /**
                 * gap-1
                 * md:gap-1
                 * lg:gap-1
                 */
                return `${prefix}gap-1`;
            case 's':
                /**
                 * gap-2
                 * md:gap-2
                 * lg:gap-2
                 */
                return `${prefix}gap-2`;
            case 'md':
                /**
                 * gap-3
                 * md:gap-3
                 * lg:gap-3
                 */
                return `${prefix}gap-3`;
            case 'l':
                /**
                 * gap-4
                 * md:gap-4
                 * lg:gap-4
                 */
                return `${prefix}gap-4`;
            case 'xl':
                /**
                 * gap-5
                 * md:gap-5
                 * lg:gap-5
                 */
                return `${prefix}gap-5`;
            case '2xl':
                /**
                 * gap-6
                 * md:gap-6
                 * lg:gap-6
                 */
                return `${prefix}gap-6`;
            case '3xl':
                /**
                 * gap-8
                 * md:gap-8
                 * lg:gap-8
                 */
                return `${prefix}gap-8`;
            case '4xl':
                /**
                 * gap-12
                 * md:gap-12
                 * lg:gap-12
                 */
                return `${prefix}gap-12`;
            case '5xl':
                /**
                 * gap-16
                 * md:gap-16
                 * lg:gap-16
                 */
                return `${prefix}gap-16`;
            case '6xl':
                /**
                 * gap-20
                 * md:gap-20
                 * lg:gap-20
                 */
                return `${prefix}gap-20`;
            case '7xl':
                /**
                 * gap-24
                 * md:gap-24
                 * lg:gap-24
                 */
                return `${prefix}gap-24`;
            case '8xl':
                /**
                 * gap-32
                 * md:gap-32
                 * lg:gap-32
                 */
                return `${prefix}gap-32`;
            case '9xl':
                /**
                 * gap-40
                 * md:gap-40
                 * lg:gap-40
                 */
                return `${prefix}gap-40`;
            default:
                return '';
        }
    }

    getFlexboxClasses(styles: IFlexStyle): string[] {
        const res: string[] = [];
        if (styles.flexDirection) res.push(this._getFlexDirectionStyle(styles.flexDirection));
        if (styles.flexDirectionTablet) res.push(this._getFlexDirectionStyle(styles.flexDirectionTablet, 'tablet'));
        if (styles.flexDirectionDesktop) res.push(this._getFlexDirectionStyle(styles.flexDirectionDesktop, 'desktop'));

        if (styles.flexAlign) res.push(this._getFlexAlignStyle(styles.flexAlign));
        if (styles.flexAlignTablet) res.push(this._getFlexAlignStyle(styles.flexAlignTablet, 'tablet'));
        if (styles.flexAlignDesktop) res.push(this._getFlexAlignStyle(styles.flexAlignDesktop, 'desktop'));

        if (styles.flexJustify) res.push(this._getFlexJustifyStyle(styles.flexJustify));
        if (styles.flexJustifyTablet) res.push(this._getFlexJustifyStyle(styles.flexJustifyTablet, 'tablet'));
        if (styles.flexJustifyDesktop) res.push(this._getFlexJustifyStyle(styles.flexJustifyDesktop, 'desktop'));

        if (styles.flexGap) res.push(this._getFlexGapStyle(styles.flexGap));
        if (styles.flexGapTablet) res.push(this._getFlexGapStyle(styles.flexGapTablet, 'tablet'));
        if (styles.flexGapDesktop) res.push(this._getFlexGapStyle(styles.flexGapDesktop, 'desktop'));

        if (styles.gap) res.push(this._getFlexGapStyle(styles.gap));
        if (styles.gapTablet) res.push(this._getFlexGapStyle(styles.gapTablet, 'tablet'));
        if (styles.gapDesktop) res.push(this._getFlexGapStyle(styles.gapDesktop, 'desktop'));

        if (styles.flexWrap) res.push(this._getFlexWrapStyle(styles.flexWrap));
        return res;
    }

    /* Gradient Direction ------------------------------------------------------------- */
    private _getGradientDirectionStyle(direction?: GradientDirectionOption, screenSize: ScreenSize = ''): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (direction) {
            case 'left':
                /**
                 * bg-gradient-to-l
                 * md:bg-gradient-to-l
                 * lg:bg-gradient-to-l
                 */
                return `${prefix}bg-gradient-to-l`;
            case 'right':
                /**
                 * bg-gradient-to-r
                 * md:bg-gradient-to-r
                 * lg:bg-gradient-to-r
                 */
                return `${prefix}bg-gradient-to-r`;
            case 'top':
                /**
                 * bg-gradient-to-t
                 * md:bg-gradient-to-t
                 * lg:bg-gradient-to-t
                 */
                return `${prefix}bg-gradient-to-t`;
            case 'bottom':
                /**
                 * bg-gradient-to-b
                 * md:bg-gradient-to-b
                 * lg:bg-gradient-to-b
                 */
                return `${prefix}bg-gradient-to-b`;
            case 'bottom-left':
                /**
                 * bg-gradient-to-bl
                 * md:bg-gradient-to-bl
                 * lg:bg-gradient-to-bl
                 */
                return `${prefix}bg-gradient-to-bl`;
            case 'bottom-right':
                /**
                 * bg-gradient-to-br
                 * md:bg-gradient-to-br
                 * lg:bg-gradient-to-br
                 */
                return `${prefix}bg-gradient-to-br`;
            case 'top-left':
                /**
                 * bg-gradient-to-tl
                 * md:bg-gradient-to-tl
                 * lg:bg-gradient-to-tl
                 */
                return `${prefix}bg-gradient-to-tl`;
            case 'top-right':
                /**
                 * bg-gradient-to-tr
                 * md:bg-gradient-to-tr
                 * lg:bg-gradient-to-tr
                 */
                return `${prefix}bg-gradient-to-tr`;
            default:
                return '';
        }
    }

    getGradientDirectionClasses(styles: IGradientDirection): string[] {
        const res: string[] = [];
        if (styles.gradientDirection) res.push(this._getGradientDirectionStyle(styles.gradientDirection));
        if (styles.gradientDirectionTablet) res.push(this._getGradientDirectionStyle(styles.gradientDirectionTablet, 'tablet'));
        if (styles.gradientDirectionDesktop) res.push(this._getGradientDirectionStyle(styles.gradientDirectionDesktop, 'desktop'));
        return res;
    }


    /* Background Color ------------------------------------------------------------------ */
    private _getBackgroundColorStyle(color?: BackgroundColorOption, screenSize: ScreenSize = ''): string {
        const prefix = `${this._getResponsivePrefix(screenSize)}`;
        switch (color) {
            case 'primary':
                /**
                 * bg-primary
                 * md:bg-primary
                 * lg:bg-primary
                 */
                return `${prefix}bg-primary`;
            case 'secondary':
                /**
                 * bg-secondary
                 * md:bg-secondary
                 * lg:bg-secondary
                 */
                return `${prefix}bg-secondary`;
            case 'accent-light':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light`;
            case 'accent-dark':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark`;
            case 'accent':
                /**
                 * bg-accent
                 * md:bg-accent
                 * lg:bg-accent
                 */
                return `${prefix}bg-accent`;
            case 'grey-dark':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark`;
            case 'grey-light':
                /**
                 * bg-grey-light
                 * md:bg-grey-light
                 * lg:bg-grey-light
                 */
                return `${prefix}bg-grey-light`;
            case 'grey':
                /**
                 * bg-grey
                 * md:bg-grey
                 * lg:bg-grey
                 */
                return `${prefix}bg-grey`;
            case 'white':
                /**
                 * bg-white
                 * md:bg-white
                 * lg:bg-white
                 */
                return `${prefix}bg-white`;
            case 'transparent':
                /**
                 * bg-transparent
                 * md:bg-transparent
                 * lg:bg-transparent
                 */
                return `${prefix}bg-transparent`;
            default:
                return '';
        }
    }

    getBackgroundColorClasses(styles: IBackgroundColorStyle): string[] {
        const res: string[] = [];
        if (styles.bgColor) res.push(this._getBackgroundColorStyle(styles.bgColor));
        if (styles.bgColorTablet) res.push(this._getBackgroundColorStyle(styles.bgColorTablet, 'tablet'));
        if (styles.bgColorDesktop) res.push(this._getBackgroundColorStyle(styles.bgColorDesktop, 'desktop'));
        if (styles.backgroundColor) res.push(this._getBackgroundColorStyle(styles.backgroundColor));
        return res;
    }

    /* Background Shade ------------------------------------------------------------------ */
    private _getBackgroundShadeStyle(color?: BackgroundShadesOption, screenSize: ScreenSize = ''): string {
        const prefix = `${this._getResponsivePrefix(screenSize)}`;
        switch (color) {
            case 'primary':
                /**
                 * bg-primary
                 * md:bg-primary
                 * lg:bg-primary
                 */
                return `${prefix}bg-primary`;
            case 'primary-90':
                /**
                 * bg-primary90
                 * md:bg-primary90
                 * lg:bg-primary90
                 */
                return `${prefix}bg-primary90`;
            case 'primary-80':
                /**
                 * bg-primary80
                 * md:bg-primary80
                 * lg:bg-primary80
                 */
                return `${prefix}bg-primary80`;
            case 'primary-70':
                /**
                 * bg-primary70
                 * md:bg-primary70
                 * lg:bg-primary70
                 */
                return `${prefix}bg-primary70`;
            case 'primary-60':
                /**
                 * bg-primary60
                 * md:bg-primary60
                 * lg:bg-primary60
                 */
                return `${prefix}bg-primary60`;
            case 'primary-50':
                /**
                 * bg-primary50
                 * md:bg-primary50
                 * lg:bg-primary50
                 */
                return `${prefix}bg-primary50`;
            case 'primary-40':
                /**
                 * bg-primary40
                 * md:bg-primary40
                 * lg:bg-primary40
                 */
                return `${prefix}bg-primary40`;
            case 'primary-30':
                /**
                 * bg-primary30
                 * md:bg-primary30
                 * lg:bg-primary30
                 */
                return `${prefix}bg-primary30`;
            case 'primary-20':
                /**
                 * bg-primary20
                 * md:bg-primary20
                 * lg:bg-primary20
                 */
                return `${prefix}bg-primary20`;
            case 'primary-10':
                /**
                 * bg-primary10
                 * md:bg-primary10
                 * lg:bg-primary10
                 */
                return `${prefix}bg-primary10`;
            case 'secondary-10':
                /**
                 * bg-secondary10
                 * md:bg-secondary10
                 * lg:bg-secondary10
                 */
                return `${prefix}bg-secondary10`;
            case 'secondary-20':
                /**
                 * bg-secondary20
                 * md:bg-secondary20
                 * lg:bg-secondary20
                 */
                return `${prefix}bg-secondary20`;
            case 'secondary-30':
                /**
                 * bg-secondary30
                 * md:bg-secondary30
                 * lg:bg-secondary30
                 */
                return `${prefix}bg-secondary30`;
            case 'secondary-40':
                /**
                 * bg-secondary40
                 * md:bg-secondary40
                 * lg:bg-secondary40
                 */
                return `${prefix}bg-secondary40`;
            case 'secondary-50':
                /**
                 * bg-secondary50
                 * md:bg-secondary50
                 * lg:bg-secondary50
                 */
                return `${prefix}bg-secondary50`;
            case 'secondary-60':
                /**
                 * bg-secondary60
                 * md:bg-secondary60
                 * lg:bg-secondary60
                 */
                return `${prefix}bg-secondary60`;
            case 'secondary-70':
                /**
                 * bg-secondary70
                 * md:bg-secondary70
                 * lg:bg-secondary70
                 */
                return `${prefix}bg-secondary70`;
            case 'secondary-80':
                /**
                 * bg-secondary80
                 * md:bg-secondary80
                 * lg:bg-secondary80
                 */
                return `${prefix}bg-secondary80`;
            case 'secondary-90':
                /**
                 * bg-secondary90
                 * md:bg-secondary90
                 * lg:bg-secondary90
                 */
                return `${prefix}bg-secondary90`;
            case 'secondary':
                /**
                 * bg-secondary
                 * md:bg-secondary
                 * lg:bg-secondary
                 */
                return `${prefix}bg-secondary`;
            case 'accent-10':
                /**
                 * bg-accent10
                 * md:bg-accent10
                 * lg:bg-accent10
                 */
                return `${prefix}bg-accent10`;
            case 'accent-20':
                /**
                 * bg-accent20
                 * md:bg-accent20
                 * lg:bg-accent20
                 */
                return `${prefix}bg-accent20`;
            case 'accent-30':
                /**
                 * bg-accent30
                 * md:bg-accent30
                 * lg:bg-accent30
                 */
                return `${prefix}bg-accent30`;
            case 'accent-40':
                /**
                 * bg-accent40
                 * md:bg-accent40
                 * lg:bg-accent40
                 */
                return `${prefix}bg-accent40`;
            case 'accent-50':
                /**
                 * bg-accent50
                 * md:bg-accent50
                 * lg:bg-accent50
                 */
                return `${prefix}bg-accent50`;
            case 'accent-60':
                /**
                 * bg-accent60
                 * md:bg-accent60
                 * lg:bg-accent60
                 */
                return `${prefix}bg-accent60`;
            case 'accent-70':
                /**
                 * bg-accent70
                 * md:bg-accent70
                 * lg:bg-accent70
                 */
                return `${prefix}bg-accent70`;
            case 'accent-80':
                /**
                 * bg-accent80
                 * md:bg-accent80
                 * lg:bg-accent80
                 */
                return `${prefix}bg-accent80`;
            case 'accent-90':
                /**
                 * bg-accent90
                 * md:bg-accent90
                 * lg:bg-accent90
                 */
                return `${prefix}bg-accent90`;
            case 'accent':
                /**
                 * bg-accent
                 * md:bg-accent
                 * lg:bg-accent
                 */
                return `${prefix}bg-accent`;
            case 'grey-10':
                /**
                 * bg-grey10
                 * md:bg-grey10
                 * lg:bg-grey10
                 */
                return `${prefix}bg-grey10`;
            case 'grey-20':
                /**
                 * bg-grey20
                 * md:bg-grey20
                 * lg:bg-grey20
                 */
                return `${prefix}bg-grey20`;
            case 'grey-30':
                /**
                 * bg-grey30
                 * md:bg-grey30
                 * lg:bg-grey30
                 */
                return `${prefix}bg-grey30`;
            case 'grey-40':
                /**
                 * bg-grey40
                 * md:bg-grey40
                 * lg:bg-grey40
                 */
                return `${prefix}bg-grey40`;
            case 'grey-50':
                /**
                 * bg-grey50
                 * md:bg-grey50
                 * lg:bg-grey50
                 */
                return `${prefix}bg-grey50`;
            case 'grey-60':
                /**
                 * bg-grey60
                 * md:bg-grey60
                 * lg:bg-grey60
                 */
                return `${prefix}bg-grey60`;
            case 'grey-70':
                /**
                 * bg-grey70
                 * md:bg-grey70
                 * lg:bg-grey70
                 */
                return `${prefix}bg-grey70`;
            case 'grey-80':
                /**
                 * bg-grey80
                 * md:bg-grey80
                 * lg:bg-grey80
                 */
                return `${prefix}bg-grey80`;
            case 'grey-90':
                /**
                 * bg-grey90
                 * md:bg-grey90
                 * lg:bg-grey90
                 */
                return `${prefix}bg-grey90`;
            case 'grey':
                /**
                 * bg-grey
                 * md:bg-grey
                 * lg:bg-grey
                 */
                return `${prefix}bg-grey`;
            case 'grey-dark-10':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark10`;
            case 'grey-dark-20':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark20`;
            case 'grey-dark-30':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark30`;
            case 'grey-dark-40':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark40`;
            case 'grey-dark-50':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark50`;
            case 'grey-dark-60':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark60`;
            case 'grey-dark-70':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark70`;
            case 'grey-dark-80':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark80`;
            case 'grey-dark-90':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark90`;
            case 'grey-dark':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey-dark`;
            case 'accent-light-10':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light10`;
            case 'accent-light-20':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light20`;
            case 'accent-light-30':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light30`;
            case 'accent-light-40':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light40`;
            case 'accent-light-50':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light50`;
            case 'accent-light-60':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light60`;
            case 'accent-light-70':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light70`;
            case 'accent-light-80':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light80`;
            case 'accent-light-90':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light90`;
            case 'accent-light':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light`;
            case 'accent-dark-10':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark10`;
            case 'accent-dark-20':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark20`;
            case 'accent-dark-30':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark30`;
            case 'accent-dark-40':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark40`;
            case 'accent-dark-50':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark50`;
            case 'accent-dark-60':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark60`;
            case 'accent-dark-70':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark70`;
            case 'accent-dark-80':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark80`;
            case 'accent-dark-90':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark90`;
            case 'accent-dark':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark`;
            default:
                return '';
        }
    }

    getBackgroundShadeClasses(styles: IBackgroundShadeStyle): string[] {
        const res: string[] = [];
        if (styles.bgShadeColor) res.push(this._getBackgroundShadeStyle(styles.bgShadeColor));
        if (styles.bgShadeColorTablet) res.push(this._getBackgroundShadeStyle(styles.bgShadeColorTablet, 'tablet'));
        if (styles.bgShadeColorDesktop) res.push(this._getBackgroundShadeStyle(styles.bgShadeColorDesktop, 'desktop'));
        return res;
    }

    /* Background Opacity ------------------------------------------------------------------ */
    private _getBackgroundOpacityStyle(color?: BackgroundOpacityOption, screenSize: ScreenSize = ''): string {
        const prefix = `${this._getResponsivePrefix(screenSize)}`;
        switch (color) {
            case '10':
                /**
                 * bg-opacity-10
                 * md:bg-opacity-10
                 * lg:bg-opacity-10
                 */
                return `${prefix}bg-opacity-10`;
            case '20':
                /**
                 * bg-opacity-20
                 * md:bg-opacity-20
                 * lg:bg-opacity-20
                 */
                return `${prefix}bg-opacity-20`;
            case '30':
                /**
                 * bg-opacity-30
                 * md:bg-opacity-30
                 * lg:bg-opacity-30
                 */
                return `${prefix}bg-opacity-30`;
            case '40':
                /**
                 * bg-opacity-40
                 * md:bg-opacity-40
                 * lg:bg-opacity-40
                 */
                return `${prefix}bg-opacity-40`;
            case '50':
                /**
                 * bg-opacity-50
                 * md:bg-opacity-50
                 * lg:bg-opacity-50
                 */
                return `${prefix}bg-opacity-50`;
            case '60':
                /**
                 * bg-opacity-60
                 * md:bg-opacity-60
                 * lg:bg-opacity-60
                 */
                return `${prefix}bg-opacity-60`;
            case '70':
                /**
                 * bg-opacity-70
                 * md:bg-opacity-70
                 * lg:bg-opacity-70
                 */
                return `${prefix}bg-opacity-70`;
            case '80':
                /**
                 * bg-opacity-80
                 * md:bg-opacity-80
                 * lg:bg-opacity-80
                 */
                return `${prefix}bg-opacity-80`;
            case '90':
                /**
                 * bg-opacity-90
                 * md:bg-opacity-90
                 * lg:bg-opacity-90
                 */
                return `${prefix}bg-opacity-90`;
            case '100':
                /**
                 * bg-opacity-100
                 * md:bg-opacity-100
                 * lg:bg-opacity-100
                 */
                return `${prefix}bg-opacity-100`;
            default:
                return '';
        }
    }

    getBackgroundOpacityClasses(styles: IBackgroundOpacityStyle): string[] {
        const res: string[] = [];
        if (styles.backgroundOpacity) res.push(this._getBackgroundOpacityStyle(styles.backgroundOpacity));
        if (styles.bgOpacity) res.push(this._getBackgroundOpacityStyle(styles.bgOpacity));
        if (styles.bgOpacityTablet) res.push(this._getBackgroundOpacityStyle(styles.bgOpacityTablet, 'tablet'));
        if (styles.bgOpacityDesktop) res.push(this._getBackgroundOpacityStyle(styles.bgOpacityDesktop, 'desktop'));
        return res;
    }

    /* Background Size ------------------------------------------------------------------ */
    private _getBackgroundSizeStyle(size?: BackgroundSizeOption, screenSize: ScreenSize = ''): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (size) {
            case 'auto':
                /**
                 * bg-auto
                 * md:bg-auto
                 * lg:bg-auto
                 */
                return `${prefix}bg-auto`;
            case 'cover':
                /**
                 * bg-cover
                 * md:bg-cover
                 * lg:bg-cover
                 */
                return `${prefix}bg-cover`;
            case 'contain':
                /**
                 * bg-contain
                 * md:bg-contain
                 * lg:bg-contain
                 */
                return `${prefix}bg-contain`;
            case 'unset':
            default:
                return '';
        }
    }

    getBackgroundSizeClasses(styles: IBackgroundSizeStyle): string[] {
        const res: string[] = [];
        if (styles?.backgroundSize) res.push(this._getBackgroundSizeStyle(styles.backgroundSize));
        if (styles?.backgroundSizeTablet) res.push(this._getBackgroundSizeStyle(styles.backgroundSizeTablet, 'tablet'));
        if (styles?.backgroundSizeDesktop) res.push(this._getBackgroundSizeStyle(styles.backgroundSizeDesktop, 'desktop'));
        return res;
    }

    /* Background Position ------------------------------------------------------------- */
    private _getBackgroundPositionStyle(size?: BackgroundPositionOption, screenSize: ScreenSize = ''): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (size) {
            case 'bottom':
                /**
                 * bg-bottom
                 * md:bg-bottom
                 * lg:bg-bottom
                 */
                return `${prefix}bg-bottom`;
            case 'center':
                /**
                 * bg-center
                 * md:bg-center
                 * lg:bg-center
                 */
                return `${prefix}bg-center`;
            case 'left':
                /**
                 * bg-left
                 * md:bg-left
                 * lg:bg-left
                 */
                return `${prefix}bg-left`;
            case 'left-bottom':
                /**
                 * bg-left-bottom
                 * md:bg-left-bottom
                 * lg:bg-left-bottom
                 */
                return `${prefix}bg-left-bottom`;
            case 'left-top':
                /**
                 * bg-left-top
                 * md:bg-left-top
                 * lg:bg-left-top
                 */
                return `${prefix}bg-left-top`;
            case 'right':
                /**
                 * bg-right
                 * md:bg-right
                 * lg:bg-right
                 */
                return `${prefix}bg-right`;
            case 'right-bottom':
                /**
                 * bg-right-bottom
                 * md:bg-right-bottom
                 * lg:bg-right-bottom
                 */
                return `${prefix}bg-right-bottom`;
            case 'right-top':
                /**
                 * bg-right-top
                 * md:bg-right-top
                 * lg:bg-right-top
                 */
                return `${prefix}bg-right-top`;
            case 'top':
                /**
                 * bg-top
                 * md:bg-top
                 * lg:bg-top
                 */
                return `${prefix}bg-top`;
            case 'unset':
            default:
                return '';
        }
    }

    getBackgroundPositionClasses(styles: IBackgroundPositionStyle): string[] {
        const res: string[] = [];
        if (styles?.backgroundPosition) res.push(this._getBackgroundPositionStyle(styles.backgroundPosition));
        if (styles?.backgroundPositionTablet) res.push(this._getBackgroundPositionStyle(styles.backgroundPositionTablet, 'tablet'));
        if (styles?.backgroundPositionDesktop) res.push(this._getBackgroundPositionStyle(styles.backgroundPositionDesktop, 'desktop'));

        if (styles?.bgPosition) res.push(this._getBackgroundPositionStyle(styles.bgPosition));
        if (styles?.bgPositionTablet) res.push(this._getBackgroundPositionStyle(styles.bgPositionTablet, 'tablet'));
        if (styles?.bgPositionDesktop) res.push(this._getBackgroundPositionStyle(styles.bgPositionDesktop, 'desktop'));
        return res;
    }


    /* Opacity ------------------------------------------------------------------ */
    private _getOpacityStyle(color?: OpacityOption, screenSize: ScreenSize = ''): string {
        const prefix = `${this._getResponsivePrefix(screenSize)}`;
        switch (color) {
            case '10':
                /**
                 * opacity-10
                 * md:opacity-10
                 * lg:opacity-10
                 */
                return `${prefix}opacity-10`;
            case '20':
                /**
                 * opacity-20
                 * md:opacity-20
                 * lg:opacity-20
                 */
                return `${prefix}opacity-20`;
            case '30':
                /**
                 * opacity-30
                 * md:opacity-30
                 * lg:opacity-30
                 */
                return `${prefix}opacity-30`;
            case '40':
                /**
                 * opacity-40
                 * md:opacity-40
                 * lg:opacity-40
                 */
                return `${prefix}opacity-40`;
            case '50':
                /**
                 * opacity-50
                 * md:opacity-50
                 * lg:opacity-50
                 */
                return `${prefix}opacity-50`;
            case '60':
                /**
                 * opacity-60
                 * md:opacity-60
                 * lg:opacity-60
                 */
                return `${prefix}opacity-60`;
            case '70':
                /**
                 * opacity-70
                 * md:opacity-70
                 * lg:opacity-70
                 */
                return `${prefix}opacity-70`;
            case '80':
                /**
                 * opacity-80
                 * md:opacity-80
                 * lg:opacity-80
                 */
                return `${prefix}opacity-80`;
            case '90':
                /**
                 * opacity-90
                 * md:opacity-90
                 * lg:opacity-90
                 */
                return `${prefix}opacity-90`;
            case '100':
                /**
                 * opacity-100
                 * md:opacity-100
                 * lg:opacity-100
                 */
                return `${prefix}opacity-100`;
            default:
                return '';
        }
    }

    getOpacityClasses(styles: IOpacityStyle): string[] {
        const res: string[] = [];
        if (styles.opacity) res.push(this._getOpacityStyle(styles.opacity));
        return res;
    }
}
